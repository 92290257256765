// -------------------------------------------------------------------
//  mediaquery.scss
// -------------------------------------------------------------------

@mixin media($mediaSize) {
  @if $mediaSize == sp {
    // SP用
    @media (max-width: $BREAK_POINT_SP - 1) {
      @content;
    }
  } @else if $mediaSize == tb {
    // SP & タブレット用
    @media (max-width: $BREAK_POINT_TB - 1) {
      @content;
    }
  } @else if $mediaSize == tbonly {
    // タブレット用
    @media (min-width: $BREAK_POINT_SP) and (max-width: $BREAK_POINT_TB - 1) {
      @content;
    }
  } @else if $mediaSize == pctb {
    // PC & タブレット用
    @media (min-width: $BREAK_POINT_SP) {
      @content;
    }
  } @else if $mediaSize == pc {
    // PC用(狭)
    @media (min-width: $BREAK_POINT_TB) {
      @content;
    }
  } @else if $mediaSize == wide {
    // PC用(広)
    @media (min-width: $BREAK_POINT_PC) {
      @content;
    }
  } @else {
    // PC用(広)
    @media (min-width: $BREAK_POINT_PC) {
      @content;
    }
  }
}

@mixin mq($min-width, $max-width) {
  @media (min-width: $min-width) and (max-width: $max-width) {
    @content;
  }
}

@mixin mq-min($min-width) {
  @media (min-width: $min-width) {
    @content;
  }
}

@mixin mq-max($max-width) {
  @media (max-width: $max-width) {
    @content;
  }
}