@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
.form-karaokesearch {
  margin-top: 0px;
  position: relative;
  display: flex;
  width: 100%;
  /*
    .artistleaf &,
    .songleaf &{
        display: none;
    }
*/
}

.karaokesearch-page .form-karaokesearch {
  margin-top: 12px;
  width: calc(100% - 136px);
}

@media (max-width: 959px) {
  .form-karaokesearch {
    display: block;
    width: 100%;
  }
  .karaokesearch-page .form-karaokesearch {
    width: 100%;
  }
}

.form-karaokesearch .input-wrap {
  max-width: 100%;
  width: 100%;
  position: relative;
  /* Reset input[type="search"] */
}

.karaokesearch-page .form-karaokesearch .input-wrap {
  max-width: 360px;
  width: calc(100% - 560px);
}

@media (max-width: 959px) {
  .form-karaokesearch .input-wrap {
    max-width: 100%;
    width: 100%;
  }
  .karaokesearch-page .form-karaokesearch .input-wrap {
    max-width: 100%;
    width: 100%;
  }
}

.form-karaokesearch .input-wrap .keyword {
  width: 100%;
  height: 30px;
  line-height: 28px;
  border: 1px solid #b5b5b5;
  outline: 0;
  padding: 0 50px 0 5px;
  border-radius: 0px;
  font-size: 14px;
}

@media (max-width: 959px) {
  .form-karaokesearch .input-wrap .keyword {
    font-size: 16px;
  }
}

.form-karaokesearch .input-wrap input[type="search"] {
  appearance: none;
}

.form-karaokesearch .input-wrap input[type="search"]::-webkit-search-decoration {
  display: none;
}

.form-karaokesearch .input-wrap input[type="search"]::-webkit-search-cancel-button {
  appearance: none;
}

.form-karaokesearch .input-wrap input[type="search"]::-ms-clear {
  visibility: hidden;
}

.form-karaokesearch .input-wrap .btn-keyword-reset {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
  border: none;
  display: none;
  background: #FFFFFF;
  outline: 0;
  cursor: pointer;
  width: 18px;
  height: 18px;
  background: url(../images/ico_times.svg) no-repeat;
  background-size: 18px;
}

.form-karaokesearch .input-wrap .btn-keyword-reset.is-show {
  display: block;
}

.form-karaokesearch .input-wrap .btn-keyword-reset i {
  font-size: 1.8rem;
  color: #b5b5b5;
  vertical-align: center;
}

.form-karaokesearch .input-wrap .btn-search {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 7px;
  background: none;
  border: none;
  outline: 0;
  cursor: pointer;
  padding: 0;
  width: 16px;
  height: 16px;
  background: url(../images/ico_search.svg) no-repeat;
  background-size: 16px;
}

.form-karaokesearch .input-wrap .btn-search i {
  font-weight: bold;
  font-size: 1.6rem;
  vertical-align: center;
}

.form-karaokesearch .input-wrap .btn-search:focus {
  opacity: 0.7;
}

.form-karaokesearch .input-wrap .history-list,
.form-karaokesearch .input-wrap .auto-complete-list {
  text-align: left;
  display: none;
  margin: 0;
  padding: 0;
  list-style: none;
  background: #FFFFFF;
  border-bottom: 1px solid #b5b5b5;
  position: absolute;
  width: 100%;
  z-index: 10;
  max-height: 70vh;
  overflow-y: auto;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
}

.form-karaokesearch .input-wrap .history-list.is-show,
.form-karaokesearch .input-wrap .auto-complete-list.is-show {
  display: block;
}

.form-karaokesearch .input-wrap .history-list::-webkit-scrollbar,
.form-karaokesearch .input-wrap .auto-complete-list::-webkit-scrollbar {
  width: 5px;
}

.form-karaokesearch .input-wrap .history-list::-webkit-scrollbar-track,
.form-karaokesearch .input-wrap .auto-complete-list::-webkit-scrollbar-track {
  background: #eaeaea;
}

.form-karaokesearch .input-wrap .history-list::-webkit-scrollbar-thumb,
.form-karaokesearch .input-wrap .auto-complete-list::-webkit-scrollbar-thumb {
  background: #b5b5b5;
  border-radius: 10px;
  box-shadow: inset 0 0 0 2px #b5b5b5;
}

.form-karaokesearch .input-wrap .history-item {
  border: 1px solid #b5b5b5;
  border-top: none;
  position: relative;
}

.form-karaokesearch .input-wrap .history-item:hover {
  background-color: #eaeaea;
  opacity: 1;
}

.form-karaokesearch .input-wrap .history-item a {
  text-decoration: none;
  font-size: 13px;
  padding: 8px 0px 8px 25px;
  color: #1c1c1c;
  display: block;
  outline: 0;
  width: calc(100% - 30px);
  background: url(../images/ico_clock.svg) no-repeat left 4px center;
  background-size: 16px;
  box-sizing: border-box;
}

.form-karaokesearch .input-wrap .history-item a:focus {
  background-color: #eaeaea;
}

.form-karaokesearch .input-wrap .history-item a:hover {
  background-color: #eaeaea;
  opacity: 1;
}

.form-karaokesearch .input-wrap .history-item span {
  display: block;
}

.form-karaokesearch .input-wrap .history-item:last-child {
  border-bottom: none;
}

.form-karaokesearch .input-wrap .history-item .btn-history-delete {
  position: absolute;
  right: 0px;
  top: 0px;
  font-size: 12px;
  color: #b5b5b5;
  cursor: pointer;
  width: 30px;
  height: 100%;
  background: url(../images/ico_times.svg) no-repeat center center;
  background-size: 15px;
}

.form-karaokesearch .input-wrap .auto-complete-item {
  border: 1px solid #b5b5b5;
  border-top: none;
  cursor: pointer;
  position: relative;
}

.form-karaokesearch .input-wrap .auto-complete-item a {
  text-decoration: none;
  font-size: 13px;
  padding: 8px 40px 8px 25px;
  color: #1c1c1c;
  display: block;
  outline: 0;
  background: url(../images/ico_search.svg) no-repeat left 4px center;
  background-size: 16px;
  box-sizing: border-box;
}

.form-karaokesearch .input-wrap .auto-complete-item a:focus {
  background-color: #eaeaea;
}

.form-karaokesearch .input-wrap .auto-complete-item a:hover {
  background-color: #eaeaea;
  opacity: 1;
}

.form-karaokesearch .input-wrap .auto-complete-item span {
  display: block;
}

.form-karaokesearch .input-wrap .auto-complete-item:last-child {
  border-bottom: none;
}

.form-karaokesearch .radio-wrap {
  width: 400px;
  margin-left: 40px;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 959px) {
  .form-karaokesearch .radio-wrap {
    width: 100%;
    margin: 10px 0 0;
  }
}

.form-karaokesearch .radio-wrap label {
  display: inline-block;
  width: 80px;
  text-align: center;
  position: relative;
  cursor: pointer;
}

@media (max-width: 959px) {
  .form-karaokesearch .radio-wrap label {
    width: 24%;
    height: 27px;
  }
}

.form-karaokesearch .radio-wrap input {
  display: none;
}

.form-karaokesearch .radio-wrap span {
  display: block;
  color: #FFFFFF;
}

.form-karaokesearch .radio-wrap input:checked + span:after {
  position: absolute;
  bottom: 0;
  top: auto;
  left: 0;
  border-radius: 0;
  content: "";
  width: 100%;
  height: 3px;
  background: #2699fb;
  padding: 0;
}

.karaokesearch-wrap {
  height: 100%;
  min-height: 60px;
  display: flex;
  align-items: center;
}

.karaokesearch-container {
  width: 100%;
}

.trendwords-wrap .trendwords-list {
  text-align: center;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.trendwords-wrap .trendwords-list::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.trendwords-wrap .trendwords-list::-webkit-scrollbar-track {
  background: transparent;
}

.trendwords-wrap .trendwords-list::-webkit-scrollbar-thumb {
  background: #b5b5b5;
  border-radius: 10px;
  box-shadow: inset 0 0 0 2px #b5b5b5;
}

.trendwords-wrap .trendwords-term {
  display: inline-block;
  font-size: 11px;
  list-style: none;
  margin-top: 3px;
  margin-right: 15px;
  padding: 2px 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: #c6000b;
  color: #FFF;
}

.trendwords-wrap .trendwords-item {
  display: inline-block;
  font-size: 13px;
  list-style: none;
  margin: 0;
  margin-top: 3px;
  margin-right: 15px;
  max-width: 30%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.trendwords-wrap .trendwords-item:last-child {
  padding-right: 0;
}
