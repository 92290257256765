@charset "UTF-8";

// Helpers
// -------------------------------------------------------------------
@import "helpers/variables";
@import "helpers/mediaquery";
@import "helpers/mixin";


// -------------------------------------------------------------------
//  カラオケ検索フォーム用css（外部サイト共通）
// -------------------------------------------------------------------

.form-karaokesearch{
    margin-top: 0px;
    position: relative;
    display: flex;
    width: 100%;

    .karaokesearch-page &{
        margin-top: 12px;
        width: calc(100% - 136px);
    }

    // TB -----------------
    @include media(tb){
        display: block;
        width: 100%;
        .karaokesearch-page &{
            width: 100%;
        }
    }

    .input-wrap{
        max-width: 100%;
        width: 100%;
        position: relative;

        .karaokesearch-page &{
            max-width: 360px;
            width: calc(100% - 560px);
        }

        // TB -----------------
        @include media(tb){
            max-width: 100%;
            width: 100%;

            .karaokesearch-page &{
                max-width: 100%;
                width: 100%;
            }
        }

        .keyword{
            width: 100%;
            height: 30px;
            line-height: 28px;
            border: 1px solid $GRAY_COLOR;
            outline: 0;
            padding: 0 50px 0 5px;
            border-radius: 0px;
            font-size: 14px;
            @include media(tb){
                font-size: 16px;
            }
        }
        /* Reset input[type="search"] */
        input[type="search"] {
            appearance: none;
            //box-sizing: content-box;
        }
        input[type="search"]:focus {
            //outline-offset: -2px;
        }
        input[type="search"]::-webkit-search-decoration {
            display: none;
        }
        input[type="search"]::-webkit-search-cancel-button {
            appearance: none;
        }
        input[type="search"]::-ms-clear {
            visibility:hidden;
        }

        .btn-keyword-reset{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 30px;
            border: none;
            display: none;
            background: $WHITE_COLOR;
            outline: 0;
            cursor: pointer;
            width: 18px;
            height: 18px;
            background: url(../images/ico_times.svg) no-repeat;
            background-size: 18px;
            &.is-show{
                display: block;
            }
            i{
                font-size: 1.8rem;
                color: $GRAY_COLOR;
                vertical-align: center;
            }
        }
        .btn-search{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 7px;
            background: none;
            border: none;
            outline: 0;
            cursor: pointer;
            padding: 0;
            width: 16px;
            height: 16px;
            background: url(../images/ico_search.svg) no-repeat;
            background-size: 16px;
            i{
                font-weight: bold;
                font-size: 1.6rem;
                vertical-align: center;
            }
            &:focus{
                opacity: 0.7;
            }
        }

        .history-list,
        .auto-complete-list{
            text-align: left;
            display: none;
            margin: 0;
            padding: 0;
            list-style: none;
            background: $WHITE_COLOR;
            border-bottom: 1px solid $GRAY_COLOR;
            position: absolute;
            width: 100%;
            z-index: 10;
            max-height: 70vh;
            overflow-y: auto;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
            &.is-show{
                display: block;
            }
            &::-webkit-scrollbar{
                width: 5px;
            }
            &::-webkit-scrollbar-track{
                background: $LIGHT_GRAY_COLOR;
            }
            &::-webkit-scrollbar-thumb{
                background: $GRAY_COLOR;
                border-radius: 10px;
                box-shadow: inset 0 0 0 2px $GRAY_COLOR;
            }
        }

        .history-item{
            border: 1px solid $GRAY_COLOR;
            border-top: none;
            position: relative;
            &:hover{
                background-color: $LIGHT_GRAY_COLOR;
                opacity: 1;
            }
            a{
                text-decoration: none;
                font-size: 13px;
                padding: 8px 0px 8px 25px;
                color: $BLACK_COLOR;
                display: block;
                outline: 0;
                width: calc(100% - 30px;);
                background: url(../images/ico_clock.svg) no-repeat left 4px center;
                background-size: 16px;
                box-sizing: border-box;
                &:focus{
                    background-color: $LIGHT_GRAY_COLOR;
                }
                &:hover{
                    background-color: $LIGHT_GRAY_COLOR;
                    opacity: 1;
                }
            }
            span{
                display: block;
            }
            &:last-child{
                border-bottom: none;
            }
        }
        .history-item .btn-history-delete{
            position: absolute;
            right: 0px;
            top: 0px;
            font-size: 12px;
            color: $GRAY_COLOR;
            cursor: pointer;
            width: 30px;
            height: 100%;
            background: url(../images/ico_times.svg) no-repeat center center;
            background-size: 15px;
        }
        .auto-complete-item{
            border: 1px solid $GRAY_COLOR;
            border-top: none;
            cursor: pointer;
            position: relative;
            a{
                text-decoration: none;
                font-size: 13px;
                padding: 8px 40px 8px 25px;
                color: $BLACK_COLOR;
                display: block;
                outline: 0;
                background: url(../images/ico_search.svg) no-repeat left 4px center;
                background-size: 16px;
                box-sizing: border-box;
                &:focus{
                    background-color: $LIGHT_GRAY_COLOR;
                }
                &:hover{
                    background-color: $LIGHT_GRAY_COLOR;
                    opacity: 1;
                }
            }
            span{
                display: block;
            }
            &:last-child{
                border-bottom: none;
            }
        }
    }





    .radio-wrap{
        width: 400px;
        margin-left: 40px;
        display: flex;
        justify-content: space-between;

        // TB -----------------
        @include media(tb){
            width: 100%;
            margin: 10px 0 0;
        }

        label{
            display: inline-block;
            width: 80px;
            text-align: center;
            position: relative;
            cursor: pointer;

            // TB -----------------
            @include media(tb){
                width: 24%;
                height: 27px;
            }
        }
        input{
            display: none;
        }
        span{
            display: block;
            color: $WHITE_COLOR;
        }
        input:checked + span{
            &:after{
                position: absolute;
                bottom: 0;
                top: auto;
                left: 0;
                border-radius: 0;
                content: "";
                width: 100%;
                height: 3px;
                background: $LINK_COLOR;
                padding: 0;
            }
        }
    }

/*
    .artistleaf &,
    .songleaf &{
        display: none;
    }
*/
}

.karaokesearch-wrap{
    height: 100%;
    min-height: 60px;
    display: flex;
    align-items: center;
}
.karaokesearch-container{
    width: 100%;
}
.trendwords-wrap{
    .trendwords-list{
        text-align: center;
        margin: 0;
        padding: 0;
        white-space: nowrap;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        &::-webkit-scrollbar{
            width: 3px;
            height: 3px;
        }
        &::-webkit-scrollbar-track{
            background: transparent;
        }
        &::-webkit-scrollbar-thumb{
            background: $GRAY_COLOR;
            border-radius: 10px;
            box-shadow: inset 0 0 0 2px $GRAY_COLOR;
        }
    }
    .trendwords-term{
        display: inline-block;
        font-size: 11px;
        list-style: none;
        margin-top: 3px;
        margin-right: 15px;
        padding: 2px 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        background: #c6000b;
        color: #FFF;
    }
    .trendwords-item{
        display: inline-block;
        font-size: 13px;
        list-style: none;
        margin: 0;
        margin-top: 3px;
        margin-right: 15px;
        max-width: 30%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:last-child{
            padding-right: 0;
        }
    }
}
