// -------------------------------------------------------------------
//  variables.scss
// -------------------------------------------------------------------

// Width
// -------------------------------------------------------------------
$SITE_WIDTH: 1280px;
$BREAK_POINT_SP: 560px;
$BREAK_POINT_TB: 960px;
$BREAK_POINT_PC: 1080px;

// Header Height
// -------------------------------------------------------------------
$HEADER_HEIGHT_PC: 54px;
$HEADER_HEIGHT_TB: 54px;
$HEADER_HEIGHT_SP: 54px;
$HEADER_HEIGHT_TB_KARAOKESEARCH: 117px;
$HEADER_HEIGHT_SP_KARAOKESEARCH: 117px;

// Font
// -------------------------------------------------------------------
$BASE_FONT_SIZE: 14;
$BASE_FONT_SIZE_SP: 12;
$BASE_LINE_HEIGHT: 1.5;
$BASE_FONT_FAMILY:  '游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic', 'メイリオ', Meiryo, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic ProN', sans-serif;
$EN_FONT_FAMILY: sans-serif;
//$FONT_AWESOME: "Font Awesome 5 Pro";

// Web Font
// -------------------------------------------------------------------
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP);
$NOTO_SANS_JP: 'Noto Sans JP', sans-serif;
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
$OPEN_SANS: 'Open Sans', sans-serif;

// Color
// -------------------------------------------------------------------
$BASE_BG_COLOR: #eaeaea;
$BASE_TEXT_COLOR: #1c1c1c;
$SUB_TEXT_COLOR: #4d4d4d;
$GRAY_TEXT_COLOR: #898989;
$WHITE_COLOR: #FFFFFF;
$WHITE_COLOR2: #F2F2F2;
$BLACK_COLOR: #1c1c1c;
$DARK_GRAY_COLOR: #666666;
$GRAY_COLOR: #b5b5b5;
$GRAY_COLOR2: #404040;
$LIGHT_GRAY_COLOR: #eaeaea;
$RED_COLOR: #fe6b71;
$RED_COLOR2: #EE1A26;
$RED_COLOR3: #990000;
$ORANGE_COLOR: #fbb03b;
$BLUE_COLOR: #003399;
$BORDER_COLOR: #CCCCCC;
$GREEN_COLOR: #8dc33e;
$LIGHT_BLUE_COLOR: #2699fb;
$MOVIE_COLOR: #0099CC;
$MUSIC_COLOR: #CC2960;

// Link Color
// -------------------------------------------------------------------
$LINK_COLOR: #2699fb;
$LINK_HOVER_COLOR: #2699fb;
$LINK_VISITED_COLOR: #2699fb;
$LINK_ACTIVE_COLOR: #2699fb;

// Image Path
// -------------------------------------------------------------------
$IMAGE_DIR: '../images/';
